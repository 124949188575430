import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useToasts } from 'react-cooked-bread';
import * as UAParser from 'ua-parser-js';
import { DEFAULT_APP_STATES } from 'lib/constants';
import { SafariIcon } from 'components/icons';

const AppStateContext = createContext({
  ...DEFAULT_APP_STATES,
  isDesktop: false,
});

const SafariWarning = () => (
  <article className="safari-warning">
    <SafariIcon className="safari-warning_logo" />
    <div className="safari-warning_description">
      Screenshotr is not optimized for Safari, for a better experience please
      use{' '}
      <a
        href="https://www.google.com/chrome/"
        target="_blank"
        rel="noopener norefferer"
      >
        Google Chrome
      </a>
    </div>
  </article>
);

const AppStateProvider = ({ children }) => {
  const [appStates, setAppStates] = useState(DEFAULT_APP_STATES);

  const isDesktop = appStates.deviceType.value === 'desktop';

  const updateAppStates = useCallback((newAppStates) => {
    setAppStates((prevAppStates) => ({
      ...prevAppStates,
      ...newAppStates,
    }));
  }, []);

  // Remove service workers
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }
  }, []);

  // Add check for Safari
  const { addToast } = useToasts();
  useEffect(() => {
    const ua = new UAParser();
    ua.setUA(window.navigator.userAgent);
    const isSafari = ua.getBrowser().name.includes('Safari');
    if (isSafari) {
      addToast(<SafariWarning />, {
        type: 'warning',
        id: 'browser-warning',
        autoDismiss: false,
      });
    }
    updateAppStates({ isSafari });
  }, []);

  return (
    <AppStateContext.Provider
      value={{
        appStates: { ...appStates, isDesktop },
        updateAppStates,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

const useAppState = () => useContext(AppStateContext);

export { AppStateProvider, useAppState };
export default AppStateContext;
