import { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useToasts } from 'react-cooked-bread';
import ColorThief from 'colorthief/dist/color-thief.mjs';
import { useOptions } from 'contexts/options-context';
import { useAppState } from 'contexts/app-state-context';
import { getCorrectUrl, getDomain } from 'util/url';
import { getFormattedTimeStamp } from 'util/general';
import downloadScreenshot from 'util/screenshot';
import { pullImage, pullFavicon } from 'util/api';
import { rgbToHex } from 'util/color';

const OutputContext = createContext();

const OutputProvider = ({ children }) => {
  const { addToast, removeToast } = useToasts();
  const { appStates, updateAppStates } = useAppState();
  const { isDesktop, isSafari } = appStates;
  const { options } = useOptions();
  const resolution = options.resolution.value;
  const mobileDevice = options.mobileDevice.value;
  const { searchURL, fileType, outputQuality, outputScale } = options;
  const [isDownloadLoading, setDownloadLoading] = useState(false);

  const [output, setOutput] = useState({
    screenshot: '',
    favicon: '',
    targetURL: '',
    isUpload: false,
  });

  const { targetURL } = output;

  const cleanURL = getDomain(targetURL);

  const updateOutput = (newOutput) => {
    setOutput((prevOutput) => ({
      ...prevOutput,
      ...newOutput,
    }));
  };

  // Generate color palette from new image
  useEffect(() => {
    if (output.screenshot) {
      const img = new Image(resolution.width, resolution.height);
      const handleImageLoad = () => {
        const colorThief = new ColorThief();
        const palette = colorThief
          .getPalette(img, 6)
          .map((rgb) => rgbToHex(...rgb));
        updateOutput({ palette });
        img.removeEventListener('load', handleImageLoad);
      };
      img.addEventListener('load', handleImageLoad);
      img.src = output.screenshot;
    }
  }, [output.screenshot, resolution]);

  const userAgentRef = useRef();
  userAgentRef.current = isDesktop
    ? 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/86.0.4240.75 Safari/537.36'
    : (userAgentRef.current = mobileDevice.userAgent);

  useEffect(() => {
    if (!searchURL) {
      return () => {};
    }

    let isCurrent = true;

    const getScreenshot = async () => {
      const correctUrl = getCorrectUrl(searchURL);

      updateAppStates({ isLoading: !!correctUrl });

      if (correctUrl) {
        removeToast('browser-warning');
        try {
          updateOutput({
            favicon: '',
            targetURL: '',
            isUpload: false,
          });

          const [screenshot, favicon] = await Promise.all([
            pullImage(correctUrl, resolution, userAgentRef.current),
            pullFavicon(correctUrl),
          ]);

          if (isCurrent) {
            if (screenshot) {
              updateOutput({
                favicon,
                screenshot,
                targetURL: correctUrl,
              });
            } else {
              console.warn('No image returned from API', {
                correctUrl,
                resolution,
              });
              throw new Error('no image returned');
            }

            updateAppStates({ isLoading: false });
          }
        } catch (err) {
          console.warn(err, {
            searchURL,
            correctUrl,
            resolution,
          });
          updateAppStates({ isLoading: false });
          addToast(
            'There was an issue grabbing your screenshot, please try a different url or upload your screenshot manually.',
            { type: 'error' }
          );
          console.error('There was an issue grabbing your screenshot', err);
        }
      } else {
        addToast('This is an invalid URL, please correct it and try again.', {
          type: 'error',
        });
      }
    };

    getScreenshot();

    return () => {
      isCurrent = false;
    };
  }, [resolution, searchURL, addToast, updateAppStates]);

  const filenameInputRef = useRef();
  const exportRef = useRef();
  const handleDownloadClick = async () => {
    try {
      setDownloadLoading(true);
      let filename = 'screenshotr';

      if (filenameInputRef.current.value) {
        filename = filenameInputRef.current.value;
      } else {
        if (cleanURL) {
          const filenameArr = cleanURL.split('.');
          filename = filenameArr[filenameArr.length - 2];
        }
        const timestamp = getFormattedTimeStamp();
        filename = `${filename}_${timestamp}`;
      }
      const exportNode = exportRef.current;
      await downloadScreenshot({
        filename,
        node: exportNode,
        scale: outputScale.value,
        quality: outputQuality,
        fileType: fileType.value,
        isSafari,
      });
      setDownloadLoading(false);
    } catch (err) {
      console.warn(err, { output, appStates });
      addToast('There was an issue downloading your screenshot.', {
        type: 'error',
      });
      setDownloadLoading(false);
    }
  };

  return (
    <OutputContext.Provider
      value={{
        output,
        cleanURL,
        updateOutput,
        handleDownloadClick,
        exportRef,
        filenameInputRef,
        isDownloadLoading,
      }}
    >
      {children}
    </OutputContext.Provider>
  );
};

const useOutput = () => useContext(OutputContext);

export { OutputProvider, useOutput };
export default OutputContext;
