import { createContext, useState, useContext, useEffect } from 'react';
import { DEFAULT_OPTIONS, OUTPUT_SCALES } from 'lib/constants';

const OptionsContext = createContext();

const OptionsProvider = ({ children }) => {
  const [options, setOptions] = useState(DEFAULT_OPTIONS);

  const updateOptions = (newOpts) => {
    setOptions((prevOpts) => ({
      ...prevOpts,
      ...newOpts,
    }));
  };

  useEffect(() => {
    if (window.devicePixelRatio >= 2) {
      updateOptions({ outputScale: OUTPUT_SCALES[1] });
    }
  }, []);

  return (
    <OptionsContext.Provider
      value={{
        options,
        updateOptions,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};

const useOptions = () => useContext(OptionsContext);

export { OptionsProvider, useOptions };
export default OptionsContext;
