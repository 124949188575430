/* eslint-disable react/jsx-props-no-spreading */
import Svg from './svg';

const CenterMapIcon = (props) => (
  <Svg
    size={24}
    {...props}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    stroke="currentColor"
  >
    <line x1="3" x2="6" y1="12" y2="12" />
    <line x1="18" x2="21" y1="12" y2="12" />
    <line x1="12" x2="12" y1="3" y2="6" />
    <line x1="12" x2="12" y1="18" y2="21" />
    <circle cx="12" cy="12" r="6" />
  </Svg>
);

export default CenterMapIcon;
