/* eslint-disable react/jsx-props-no-spreading */
import Svg from './svg';

const Logo = (props) => (
  <Svg size={32} {...props} fill="none">
    <rect x="17" y="2" width="13" height="13" fill="#0C7FBF" />
    <rect x="17" y="17" width="13" height="13" fill="white" />
    <rect x="2" y="20" width="13" height="10" fill="#FBE317" />
    <rect x="2" y="12" width="13" height="6" fill="white" />
    <rect x="2" y="2" width="13" height="8" fill="#F50F0F" />
    <path d="M16 16H31" stroke="#333333" strokeWidth="2" />
    <path d="M1 19H16" stroke="#333333" strokeWidth="2" />
    <path d="M1 11H16" stroke="#333333" strokeWidth="2" />
    <path d="M16 1V31" stroke="#333333" strokeWidth="2" />
    <path
      d="M28 31H16H4C2.34315 31 1 29.6569 1 28V19.1111V11.5556V4C1 2.34315 2.34315 1 4 1H16H28C29.6569 1 31 2.34315 31 4V16V28C31 29.6569 29.6569 31 28 31Z"
      stroke="#333333"
      strokeWidth="2"
    />
  </Svg>
);

export default Logo;
