export const RESOLUTION_OPTIONS = [
  {
    label: '1024 × 576',
    value: {
      height: 576,
      width: 1024,
    },
  },
  {
    label: '1152 × 648',
    value: {
      height: 648,
      width: 1152,
    },
  },
  {
    label: '1280 × 720',
    value: {
      height: 720,
      width: 1280,
    },
  },
  {
    label: '1366 × 768',
    value: {
      height: 768,
      width: 1366,
    },
  },
  {
    label: '1600 × 900',
    value: {
      height: 900,
      width: 1600,
    },
  },
  {
    label: '1920 × 1080',
    value: {
      height: 1080,
      width: 1920,
    },
  },
  {
    label: '2560 × 1440',
    value: {
      height: 1440,
      width: 2560,
    },
  },
];

export const STYLE_OPTIONS = [
  {
    value: 'apple',
    label: 'Mac OS',
  },
  {
    value: 'windows10',
    label: 'Windows 10',
  },
  {
    value: 'mondrian',
    label: 'Mondrian',
  },
  {
    value: 'windows98',
    label: 'Windows 98',
  },
  {
    value: 'noBrowser',
    label: 'None',
  },
];

export const SHADOW_OPTIONS = [
  {
    value: 'shadowNone',
    label: 'None',
  },
  {
    value: 'shadowSmall',
    label: 'Small',
  },
  {
    value: 'shadowLarge',
    label: 'Large',
  },
];

export const DARK_LIGHT_OPTIONS = [
  {
    value: 'dark',
    label: 'Dark',
  },
  {
    value: 'light',
    label: 'Light',
  },
];

export const ADDRESS_BAR_OPTIONS = [
  {
    value: 'addressNone',
    label: 'None',
  },
  {
    value: 'addressNoFavicon',
    label: 'Simple',
  },
  {
    value: 'addressFull',
    label: 'Icon',
  },
];

export const BACKGROUND_TYPES = [
  {
    label: 'Solid',
    value: 'solid',
  },
  {
    label: 'Gradient',
    value: 'gradient',
  },
  {
    label: 'Image',
    value: 'image',
  },
];

export const DEVICE_TYPES = [
  {
    label: 'Desktop',
    value: 'desktop',
  },
  {
    label: 'Mobile',
    value: 'mobile',
  },
];

export const FILE_TYPES = [
  {
    label: 'PNG',
    value: 'png',
  },
  {
    label: 'JPEG',
    value: 'jpg',
  },
];

export const OUTPUT_SCALES = [
  {
    label: 'Standard',
    value: 1,
    tooltip:
      'This will export the image at the resolution listed above, good for social media platforms with a strict resolution requirement',
  },
  {
    label: 'Retina',
    value: 2,
    tooltip:
      'This will double the pixel density of the downloaded image, it will look better on retina devices',
  },
];

// https://github.com/puppeteer/puppeteer/blob/main/src/common/DeviceDescriptors.ts
export const MOBILE_DEVICES = [
  {
    label: 'iPhone X',
    value: {
      class: 'iphone-x',
      userAgent:
        'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
      physicalResolution: {
        height: 748, // Should be 812 but I left room for a header
        width: 375,
      },
      logicalResolution: {
        height: 2436,
        width: 1125,
      },
      cssResolution: {
        height: 852,
        width: 415,
        borderRadius: 60,
      },
      aspectRatio: 13 / 6,
      renderScale: 3,
      screenSize: 5.8,
      pixelDensity: 458,
    },
  },
  {
    label: 'iPhone 8',
    value: {
      class: 'iphone8',
      userAgent:
        'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
      physicalResolution: {
        height: 667,
        width: 375,
      },
      logicalResolution: {
        height: 1334,
        width: 750,
      },
      cssResolution: {
        height: 877,
        width: 423,
        borderRadius: 56,
      },
      aspectRatio: 16 / 9,
      renderScale: 2,
      screenSize: 4.7,
      pixelDensity: 326,
      colorOptions: [
        {
          label: 'Black',
          value: 'black',
        },
        {
          label: 'Silver',
          value: 'silver',
        },
        {
          label: 'Gold',
          value: 'gold',
        },
      ],
    },
  },
  {
    label: 'iPhone 8 Plus',
    value: {
      class: 'iphone8plus',
      userAgent:
        'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
      physicalResolution: {
        height: 736,
        width: 414,
      },
      logicalResolution: {
        height: 1920,
        width: 1080,
      },
      cssResolution: {
        height: 960,
        width: 466,
        borderRadius: 56,
      },
      aspectRatio: 16 / 9,
      renderScale: 3,
      screenSize: 5.5,
      pixelDensity: 401,
      colorOptions: [
        {
          label: 'Black',
          value: 'black',
        },
        {
          label: 'Silver',
          value: 'silver',
        },
        {
          label: 'Gold',
          value: 'gold',
        },
      ],
    },
  },
  {
    label: 'iPhone 5S',
    value: {
      class: 'iphone5s',
      userAgent:
        'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1',
      physicalResolution: {
        height: 568,
        width: 320,
      },
      logicalResolution: {
        height: 1136,
        width: 640,
      },
      cssResolution: {
        height: 778,
        width: 364,
        borderRadius: 50,
      },
      aspectRatio: 16 / 9,
      renderScale: 2,
      screenSize: 4,
      pixelDensity: 326,
      colorOptions: [
        {
          label: 'Black',
          value: 'black',
        },
        {
          label: 'Silver',
          value: 'silver',
        },
        {
          label: 'Gold',
          value: 'gold',
        },
      ],
    },
  },
  {
    label: 'iPhone 5C',
    value: {
      class: 'iphone5c',
      userAgent:
        'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1',
      physicalResolution: {
        height: 568,
        width: 320,
      },
      logicalResolution: {
        height: 1136,
        width: 640,
      },
      cssResolution: {
        height: 778,
        width: 364,
        borderRadius: 50,
      },
      aspectRatio: 16 / 9,
      renderScale: 2,
      screenSize: 4,
      pixelDensity: 326,
      colorOptions: [
        {
          label: 'White',
          value: 'white',
        },
        {
          label: 'Red',
          value: 'red',
        },
        {
          label: 'Yellow',
          value: 'yellow',
        },
        {
          label: 'Green',
          value: 'green',
        },
        {
          label: 'Blue',
          value: 'blue',
        },
      ],
    },
  },
  {
    label: 'iPhone 4S',
    value: {
      class: 'iphone4s',
      userAgent:
        'Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_2 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) Version/7.0 Mobile/11D257 Safari/9537.53',
      physicalResolution: {
        height: 480,
        width: 320,
      },
      logicalResolution: {
        height: 960,
        width: 640,
      },
      cssResolution: {
        height: 738,
        width: 374,
        borderRadius: 54,
      },
      aspectRatio: 4 / 3,
      renderScale: 2,
      screenSize: 3.5,
      pixelDensity: 326,
      colorOptions: [
        {
          label: 'Black',
          value: 'black',
        },
        {
          label: 'Silver',
          value: 'silver',
        },
      ],
    },
  },
  {
    label: 'iPad Mini',
    value: {
      class: 'ipad',
      userAgent:
        'Mozilla/5.0 (iPad; CPU OS 11_0 like Mac OS X) AppleWebKit/604.1.34 (KHTML, like Gecko) Version/11.0 Mobile/15A5341f Safari/604.1',
      physicalResolution: {
        height: 1024,
        width: 768,
      },
      logicalResolution: {
        height: 2048,
        width: 1536,
      },
      cssResolution: {
        height: 948,
        width: 626,
        borderRadius: 44,
      },
      aspectRatio: 4 / 3,
      renderScale: 2,
      screenSize: 7.9,
      pixelDensity: 326,
      colorOptions: [
        {
          label: 'Black',
          value: 'black',
        },
        {
          label: 'Silver',
          value: 'silver',
        },
      ],
    },
  },
  {
    label: 'Galaxy Note 8',
    value: {
      class: 'note8',
      userAgent:
        'Mozilla/5.0 (Linux; U; Android 4.2.2; en-us; GT-N5110 Build/JDQ39) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Safari/534.30',
      physicalResolution: {
        height: 846,
        width: 414,
      },
      logicalResolution: {
        height: 2960,
        width: 1440,
      },
      cssResolution: {
        height: 912,
        width: 420,
        borderRadius: 34,
      },
      aspectRatio: 18.5 / 9,
      renderScale: 3.5,
      screenSize: 6.3,
      pixelDensity: 521,
    },
  },
  {
    label: 'Nexus 5',
    value: {
      class: 'nexus5',
      userAgent:
        'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3765.0 Mobile Safari/537.36',
      physicalResolution: {
        height: 640,
        width: 360,
      },
      logicalResolution: {
        height: 1920,
        width: 1080,
      },
      cssResolution: {
        height: 668,
        width: 350,
        borderRadius: 20,
      },
      aspectRatio: 16 / 9,
      renderScale: 3,
      screenSize: 4.95,
      pixelDensity: 445,
    },
  },
  {
    label: 'Lumia 920',
    value: {
      class: 'lumia920',
      userAgent:
        'Mozilla/5.0 (Windows Phone 10.0; Android 4.2.1; Microsoft; Lumia 920) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Mobile Safari/537.36 Edge/14.14263',
      physicalResolution: {
        height: 480,
        width: 320,
      },
      logicalResolution: {
        height: 1280,
        width: 768,
      },
      cssResolution: {
        height: 732,
        width: 390,
      },
      aspectRatio: 15 / 9,
      renderScale: 8 / 3,
      screenSize: 4.5,
      pixelDensity: 332,
      colorOptions: [
        {
          label: 'Black',
          value: 'black',
        },
        {
          label: 'White',
          value: 'white',
        },
        {
          label: 'Yellow',
          value: 'yellow',
        },
        {
          label: 'Red',
          value: 'red',
        },
        {
          label: 'Blue',
          value: 'blue',
        },
      ],
    },
  },
  {
    label: 'Samsung Galaxy S5',
    value: {
      class: 's5',
      userAgent:
        'Mozilla/5.0 (Linux; Android 5.0; SM-G900P Build/LRX21T) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3765.0 Mobile Safari/537.36',
      physicalResolution: {
        height: 640,
        width: 360,
      },
      logicalResolution: {
        height: 1920,
        width: 1080,
      },
      cssResolution: {
        height: 688,
        width: 356,
        borderRadius: 42,
      },
      aspectRatio: 16 / 9,
      renderScale: 3,
      screenSize: 5.1,
      pixelDensity: 432,
      colorOptions: [
        {
          label: 'Black',
          value: 'black',
        },
        {
          label: 'White',
          value: 'white',
        },
      ],
    },
  },
  {
    label: 'HTC One',
    value: {
      class: 'htc-one',
      userAgent:
        'Mozilla/5.0 (Linux; Android 5.0.2; HTC_One Build/LRX22G) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/33.0.0.0 Mobile Safari/537.36',
      physicalResolution: {
        height: 640,
        width: 360,
      },
      logicalResolution: {
        height: 1920,
        width: 1080,
      },
      cssResolution: {
        height: 740,
        width: 370,
        borderRadius: 34,
      },
      aspectRatio: 16 / 9,
      renderScale: 3,
      screenSize: 5,
      pixelDensity: 468,
    },
  },
];

export const DEFAULT_OPTIONS = {
  resolution: RESOLUTION_OPTIONS[3], // 1366 × 768
  style: STYLE_OPTIONS[0], // apple
  background: {
    background: 'transparent',
  },
  controlScale: 1,
  shadow: SHADOW_OPTIONS[1].value, // shadow-small
  darkLight: DARK_LIGHT_OPTIONS[0].value, // dark
  address: ADDRESS_BAR_OPTIONS[2].value, // address-full
  mobileDevice: MOBILE_DEVICES[0], // iPhone X
  deviceColor: {},
  minOutputWidth: 400,
  maxOutputWidth: 4000,
  minOutputHeight: 400,
  maxOutputHeight: 4000,
  desktopOutputWidth: 1000,
  desktopOutputHeight: 625,
  desktopSubjectWidth: 850,
  mobileOutputWidth: 650,
  mobileOutputHeight: 650,
  mobileSubjectWidth: 250,
  searchURL: '',
  fileType: FILE_TYPES[0],
  outputScale: OUTPUT_SCALES[0],
  outputQuality: 100,
};

export const DEFAULT_APP_STATES = {
  isBackgroundPickerOpen: false,
  isEyeDropperActive: false,
  isLoading: false,
  deviceType: DEVICE_TYPES[0],
};

export const COOKIE_SELECTORS = [
  '[id="cookieconsent:desc"]',
  '[id="consent"]',
  '[class="consent"]',
  '[class="cookie-consent"]',
  '[class="announcement"]',
  '[aria-label="cookieconsent"]',
  '[aria-describedby="cookieconsent:desc"]',
  '[aria-label="promo"]',
];
