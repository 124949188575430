/* eslint-disable react/jsx-props-no-spreading */
import { createContext, useEffect, useContext, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useOutput } from 'contexts/output-context';

const UploadContext = createContext();

const UploadProvider = ({ children }) => {
  const { updateOutput } = useOutput();

  const handleUpload = useCallback(
    (file) => {
      updateOutput({
        favicon: '',
        targetURL: '',
        screenshot: file.base64,
        isUpload: true,
      });
      // analytics.logEvent('image_uploaded');
    },
    [updateOutput]
  );

  const handleFiles = useCallback(
    (files) => {
      // Process each file
      const allFiles = [];
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];

        // Make new FileReader
        const reader = new FileReader();

        // on reader load somthing...
        reader.onload = (e) => {
          // Make a fileInfo Object
          const fileInfo = {
            name: file.name,
            type: file.type,
            size: file.size,
            base64: e.target.result,
            file,
          };

          // Push it to the state
          if (fileInfo.base64 && /^image\//.test(fileInfo.type)) {
            allFiles.push(fileInfo);
          }

          // If all files have been processed
          if (allFiles.length === files.length) {
            handleUpload(allFiles[0]);
          }
        };

        // Convert the file to base64 text
        reader.readAsDataURL(file);
      }
    },
    [handleUpload]
  );

  useEffect(() => {
    const handlePaste = (e) => {
      const { items } = e.clipboardData;
      const files = [];
      for (let i = 0; i < items.length; i += 1) {
        const file = items[i].getAsFile();
        if (file) {
          files.push(items[i].getAsFile());
        }
      }
      if (files.length) {
        e.preventDefault();
        e.stopPropagation();
        handleFiles(files);
      }
    };

    window.addEventListener('paste', handlePaste);
    return () => window.removeEventListener('paste', handlePaste);
  }, [handleFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFiles,
  });

  return (
    <UploadContext.Provider value={{ handleFiles, isDragActive }}>
      <div
        {...getRootProps({
          onClick: (e) => e.stopPropagation(),
        })}
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <input {...getInputProps()} />
        {children}
      </div>
    </UploadContext.Provider>
  );
};

const useUpload = () => useContext(UploadContext);

export { UploadContext as default, UploadProvider, useUpload };
