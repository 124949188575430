/* eslint-disable react/jsx-props-no-spreading */
import Svg from './svg';

const UploadIcon = (props) => (
  <Svg
    size={24}
    {...props}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    stroke="currentColor"
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="17 8 12 3 7 8" />
    <line x1="12" y1="3" x2="12" y2="15" />
  </Svg>
);

export default UploadIcon;
