import html2canvas from 'html2canvas';

export const rgbToHex = (r, g, b) => {
  const toHex = (c) => c.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const getColorFromElement = async (clickEvent) => {
  const rect = clickEvent.currentTarget.getBoundingClientRect();
  const x = clickEvent.clientX - rect.left; // x position within the element.
  const y = clickEvent.clientY - rect.top; // y position within the element.

  const canvas = await html2canvas(clickEvent.currentTarget);
  const canvasWidthStyle = parseInt(canvas.style.width, 10);
  const canvasHeightStyle = parseInt(canvas.style.height, 10);
  const [r, g, b] = canvas.getContext('2d').getImageData(
    (x * canvas.width) / canvasWidthStyle, // math to handle the automatic
    (y * canvas.height) / canvasHeightStyle, // sizing applied from html2canvas
    1,
    1
  ).data;
  return rgbToHex(r, g, b);
};

export const getRandomColor = () =>
  '0123456789ABCDEF'
    .split('')
    .reduce(
      (a, c, i, arr) => (i < 6 ? a + arr[Math.floor(Math.random() * 16)] : a),
      '#'
    );
