const toBase64 = async (resp) => {
  const contentType = resp.headers.get('Content-Type');
  const buffer = await resp.arrayBuffer();
  const bytes = new Uint8Array(buffer);
  let binary = '';
  bytes.forEach((b) => {
    binary += String.fromCharCode(b);
  });
  return `data:${contentType};base64,${window.btoa(binary)}`;
};

export const pullFavicon = async (targetURL) => {
  const response = await fetch('/api/pull-favicon', {
    method: 'POST',
    body: JSON.stringify({ targetURL }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.ok && response.status === 200) {
    const imageStr = await toBase64(response);
    return imageStr;
  }

  return '';
};

export const pullImage = async (targetURL, resolution, userAgent) => {
  const response = await fetch('/api/take-screenshot', {
    method: 'POST',
    body: JSON.stringify({ targetURL, resolution, userAgent }),
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      'Accept-Encoding': '*',
    },
  });

  if (response.ok) {
    const data = await response.json();
    const image = await fetch(data.screenshot);
    const imageStr = await toBase64(image);
    return imageStr;
  }

  return '';
};

export const getUnsplashImage = async () => {
  const response = await fetch('/api/get-unsplash-image', {
    method: 'GET',
  });

  if (response.ok) {
    const data = await response.json();
    const imageResp = await fetch(data.urls.regular);
    data.imageStr = await toBase64(imageResp);
    return data;
  }

  return '';
};

export const getFullUnsplashImage = async (targetURL) => {
  const response = await fetch('/api/get-full-unsplash-image', {
    method: 'POST',
    body: JSON.stringify({ targetURL }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const data = await response.json();
    const image = await fetch(data.url);
    const imageStr = await toBase64(image);
    return imageStr;
  }

  return '';
};

export const convertSvg = async ({ svg, type, quality = 100 }) => {
  const params = new URLSearchParams({ type, quality }).toString();
  const response = await fetch(`/api/convert-svg?${params}`, {
    method: 'POST',
    body: svg,
  });

  if (response.ok && response.status === 200) {
    const imageStr = await toBase64(response);
    return imageStr;
  } else {
    const json = await response.json();
    console.error('Error Converting image from svg:\n', json);
  }

  return '';
};

export const wakeup = async () => {
  const isProduction = process.env.NODE_ENV === 'production';
  const endpointUrl = isProduction
    ? 'https://screenshotr.app'
    : 'http://localhost:3000';

  const response = await fetch(`${endpointUrl}/api/wakeup`);
  const data = await response.json();
  console.table(data);
};
